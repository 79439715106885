<script setup lang="ts">
const { locale } = useI18n()
const currentLang = locale.value

const props = defineProps({
  date: {
    type: String, // YYYY-MM-DD
    default: '',
  },
})
</script>

<template>
  <time class="ui-date" :datetime="props.date" itemprop="datePublished">
    {{ getFormattedDate(props.date, currentLang) }}
  </time>
</template>

<style lang="less">
@import (reference) '~/assets/less/mixins.less';
.ui-date {
  display: inline-block;
  color: var(--grey-1);
  line-height: 1;
  text-wrap: nowrap;
  .fluid(font-size, 10, 12);
  font-weight: 700;
}
</style>
