export default function (inputDate: string, locale: string) {
  const dateObject = new Date(inputDate)
  const ZERO_INDEX_FILLER = 1
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  }

  // Handle Icelandic separately since Intl.DateTimeFormat does not work for Icelandic on the server (yet).
  if (locale === 'is') {
    const day = dateObject.getUTCDate()
    const month = dateObject.getUTCMonth() + ZERO_INDEX_FILLER
    const year = dateObject.getUTCFullYear()

    const icelandicMonths: string[] = [
      'janúar',
      'febrúar',
      'mars',
      'apríl',
      'maí',
      'júní',
      'júlí',
      'ágúst',
      'september',
      'október',
      'nóvember',
      'desember',
    ]
    return `${day}. ${icelandicMonths[month - 1]} ${year}`
  } else {
    return new Intl.DateTimeFormat(locale, options).format(dateObject)
  }
}
