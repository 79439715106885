import type { ModuleOptions } from '@nuxtjs/i18n'
import consts from './consts'
import { i18nPages } from './i18n.pages'

export default {
  lazy: true,
  detectBrowserLanguage: false,
  langDir: './locales',
  customRoutes: 'config' as 'page' | 'config',
  strategy: 'prefix_except_default',
  defaultLocale: 'en-us',
  baseUrl: consts.baseUrl,
  locales: [
    {
      name: 'English',
      code: 'en-us',
      iso: 'en',
      file: 'en.ts',
    },
    {
      name: 'Deutsch',
      code: 'de-de',
      iso: 'de',
      file: 'de.ts',
    },
    {
      name: 'Svenska',
      code: 'sv-se',
      iso: 'sv',
      file: 'se.ts',
    },
    {
      name: 'Español',
      code: 'es-es',
      iso: 'es',
      file: 'es.ts',
    },
    {
      name: 'Français',
      code: 'fr-fr',
      iso: 'fr',
      file: 'fr.ts',
    },
    {
      name: 'Italiano',
      code: 'it-it',
      iso: 'it',
      file: 'it.ts',
    },
    {
      name: 'Íslenska',
      code: 'is',
      iso: 'is',
      file: 'is.ts',
    },
  ] as Locale[],
  pages: i18nPages,
} as Partial<ModuleOptions>
